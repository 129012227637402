import { AUTO_STYLE, animate, group, query, stagger, state, style, transition, trigger } from '@angular/animations';

const DELAY_DURATION: number = 500;
const DURATION: number = 250;

const NAVBAR = trigger('animate', [
  transition(':enter', [
    group([
      query('.animated', style({ opacity: 0, transform: 'translateY(-20px)' })),
      query(
        '.animated',
        stagger(`150ms`, [
          animate(
            `250ms 100ms cubic-bezier(0.2, 0, 0.1, 1)`,
            style({ opacity: 1, transform: 'translateY(0)' })
          )
        ])
      )
    ])
  ])
]);

const TOP_CONTENT = trigger('animate', [
  transition(':enter', [
    group([
      query('.animated', style({ opacity: 0, transform: 'translateY(-20px)' })),
      query(
        '.animated',
        stagger(`${DURATION}ms`, [
          animate(
            `500ms 100ms cubic-bezier(0.2, 0, 0.1, 1)`,
            style({ opacity: 1, transform: 'translateY(0)' })
          )
        ])
      )
    ])
  ])
]);

const FORM = trigger('animate_form', [
  transition(':enter', [
    group([
      query('.animated', style({ opacity: 0, transform: 'translateY(-20px)' })),
      query(
        '.animated',
        stagger(`150ms`, [
          animate(
            `500ms 100ms cubic-bezier(0.2, 0, 0.1, 1)`,
            style({ opacity: 1, transform: 'translateY(0)' })
          )
        ])
      )
    ])
  ])
]);

const VISIBLE_CHANGE = trigger('isVisibleChanged', [
  state('true', style({ opacity: 1 })),
  state('false', style({ opacity: 0 })),
  transition('1 => 0', animate('10ms')),
  transition('0 => 1', animate('900ms'))
]);

const FADE_TOP = trigger('fadeTop', [
  state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, transform: 'translateY(0)' })),
  state('false', style({ height: '0', visibility: 'hidden', opacity: 0, transform: 'translateY(-100%)' })),
  transition('false => true', animate(DURATION + 'ms ease-out')),
  transition('true => false', animate(DURATION + 'ms ease'))
]);

const FADE_BOTTOM = trigger('fadeBottom', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(100%)' }),
    animate(`${DURATION}ms`, style({ opacity: 1, transform: 'translateY(0)' }))
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translate(0, 0)' }),
    animate(`${DURATION}ms`, style({ opacity: 0, transform: 'translateY(100%)' }))
  ])
]);

const COLLAPSE = trigger('collapse', [
  state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })),
  state('true', style({ height: '0', visibility: 'hidden', opacity: 0 })),
  transition('false => true', animate(DURATION + 'ms ease-out')),
  transition('true => false', animate(DURATION + 'ms ease'))
]);

const CARD = trigger('animate', [
  transition(':enter', [
    group([
      query('.animated', style({ opacity: 0, transform: 'translateY(-20px)' })),
      query(
        '.animated',
        stagger(`${DURATION}ms`, [
          animate(
            `${DURATION}ms ${DELAY_DURATION}ms cubic-bezier(0.2, 0, 0.1, 1)`,
            style({ opacity: 1, transform: 'translateY(0)' })
          )
        ])
      )
    ])
  ])
]);

const COLLAPSE_INFO = trigger('collapse_info', [
  state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, 'margin-bottom': '16px' })),
  state('true', style({ height: '0', visibility: 'hidden', opacity: 0, 'margin-bottom': 0 })),
  transition('false => true', animate(DURATION + 'ms ease-out')),
  transition('true => false', animate(DURATION + 'ms ease'))
]);

const SLIDER = trigger('routeAnimations', [
  transition(':increment', slideTo('right')),
  transition(':decrement', slideTo('left'))
]);

const FADE_IN_OUT = trigger('listAnimation', [
  transition('* => *', [
    query(':leave', [stagger(100, [animate(200, style({ opacity: 0 }))])], {
      optional: true
    }),
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger(100, [animate(200, style({ opacity: 1 }))])
      ],
      { optional: true }
    )
  ])
]);


export { CARD, COLLAPSE, COLLAPSE_INFO, FADE_BOTTOM, FADE_IN_OUT, FADE_TOP, FORM, NAVBAR, SLIDER, TOP_CONTENT, VISIBLE_CHANGE };

function slideTo(direction: string) {
  const optional: object = { optional: true };
  const duration: string = '450ms ease';
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          [direction]: 0,
          inset: 0,
          placeContent: 'center',
          width: '100%',
          top: 0
        })
      ],
      optional
    ),
    query(':enter', [style({ [direction]: '-100%' })]),
    group([
      query(':leave', [animate(duration, style({ [direction]: '100%' }))]),
      query(':enter', [animate(duration, style({ [direction]: '0%' }))])
    ])
  ];
}
